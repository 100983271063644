
// const createBrowserHistory = require("history").createBrowserHistory;
// window.HistoryLibrary = createBrowserHistory();

// window.HistoryLibrary.listen(({ action, location }) => {
// 	console.log(
// 		`The current URL is ${location.pathname}${location.search}${location.hash} and state ${location.state}`
// 	);
// 	console.log(`The last navigation action was ${action}`);
// });

const ScreenSizeDetector = require('screen-size-detector');
const { customAlphabet } = require('nanoid');
const _nanoid_alphabet = '0123456789abcdefghijklmnopqrstuvwxyz';

window.getRandomKey = function(count) {
	var nanoid = customAlphabet(_nanoid_alphabet, count);
	return nanoid();
}

const screen = new ScreenSizeDetector();

if ( screen.is.mobile && _app_instance === 'helpmonks' ) {
	var _content_div = document.getElementsByClassName('body-content')[0];
	if (!_content_div) return;
	_content_div.innerHTML = `
	<div class="container">
		<div class="row mt-10">
			<div class="col-12">
				<img class="mx-auto d-block img-fluid" src="/dist2/images/Helpmonks-265px-56px.png" border="0" loading="lazy" alt="Free team collaboration tool account">
				<h1 class="display-5 text-center mt-10">Log in with your device resolution is currently not supported.</h1>
				<h1 class="display-6 text-center mt-10">Stay tuned, as we are working on a mobile version.</h1>
				<h1 class="display-6 text-center mt-10">In the meantime, please use our older mobile version at <a href="https://2.helpmonks.com">https://2.helpmonks.com</a></h1>
			</div>
		</div>
	</div>
	`;
}

if ( screen.is.mobile && _app_instance === 'razuna' ) {
	var _content_div = document.getElementsByClassName('body-content')[0];
	if (!_content_div) return;
	_content_div.innerHTML = `
	<div class="container">
		<div class="row mt-10">
			<div class="col-12">
				<img class="mx-auto d-block img-fluid" src="/dist2/images/razuna/razuna_logo_236_55.png" border="0" loading="lazy" alt="Razuna Brand Asset Management">
				<h1 class="display-5 text-center mt-10">Log in with your device resolution is currently not supported.</h1>
				<h1 class="display-6 text-center mt-10">Stay tuned, as we are working on a mobile version. Please reach out to us for feedback and comments.</h1>
				<h1 class="display-6 text-center mt-10"><a href="https://razuna.com">https://razuna.com</a></h1>
			</div>
		</div>
	</div>
	`;
}


